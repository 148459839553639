<template>
	<b-overlay :show="showLoading" rounded="sm">
		<!-- {{ selectedCategory }} -->
		<b-card>
			{{ $i18n.t('application.status') }}:
			<span class="font-weight-bold font-underline">
				<u>
					{{ computedApplicationStatus }}
				</u>
			</span>
			<!-- form -->
			<validation-observer ref="userInfoValidation">
				<b-form v-if="userApplication" class="mt-2">
					<b-row>
						<b-col sm="4" class="d-flex">
							<b-form-group
									:label="$i18n.t('application.fullName')"
									label-for="legalNameOrFullName"
									class="w-100"
							>
								<validation-provider
										#default="{ errors }"
										name="full name or legal name"
										rules="required"
								>
									<b-form-input
											v-model="data.legalNameOrFullName"
											:placeholder="$i18n.t('application.fullName') + '...'"
											name="legalNameOrFullName"
									/>
									<small class="text-danger">{{ errors[0] }}</small>
								</validation-provider>
							</b-form-group>
							<div
									style="width: 68px; padding-left: 10px"
									class="d-flex flex-column justify-content-center"
							>
								<label>{{ $i18n.t('application.is-vat-payer') }}</label>
								<b-form-checkbox
										v-model="data.isVatPayer"
										style="width: 150px"
										small
								/>
							</div>
						</b-col>
						<b-col sm="4">
							<b-form-group
									:label="$i18n.t('application.contact-person-name')"
									label-for="contactPerson"
							>
								<validation-provider
										#default="{ errors }"
										name="contact person"
										rules="required"
								>
									<b-form-input
											v-model="data.contactPerson"
											:placeholder="$i18n.t('application.contact-person-name')"
											name="contactPerson"
									/>
									<small class="text-danger">{{ errors[0] }}</small>
								</validation-provider>
							</b-form-group>
						</b-col>
						<b-col sm="4">
							<b-form-group
									:label="$i18n.t('application.contact-person-position')"
									label-for="contactPersonPosition"
							>
								<validation-provider
										#default="{ errors }"
										name="contact person position"
										rules="required"
								>
									<b-form-input
											v-model="data.contactPersonPosition"
											:placeholder="
											$i18n.t('application.contact-person-position')
										"
											name="contactPersonPosition"
									/>
									<small class="text-danger">{{ errors[0] }}</small>
								</validation-provider>
							</b-form-group>
						</b-col>
						<b-col sm="4">
							<b-form-group
									:label="$i18n.t('application.contact-person-mobile')"
									label-for="contactPersonMobile"
							>
								<validation-provider
										#default="{ errors }"
										name="contact person position"
										rules="required"
								>
									<b-form-input
											v-model="data.contactPersonMobile"
											:placeholder="$i18n.t('application.contact-person-mobile')"
											name="contactPersonMobile"
									/>
									<small class="text-danger">{{ errors[0] }}</small>
								</validation-provider>
							</b-form-group>
						</b-col>
						<b-col sm="4">
							<b-form-group
									:label="$i18n.t('application.brand-name')"
									label-for="brandName"
							>
								<validation-provider
										#default="{ errors }"
										name="brand name"
										rules="required"
								>
									<b-form-input
											v-model="data.brandName"
											:placeholder="$i18n.t('application.brand-name')"
											name="brandName"
									/>
									<small class="text-danger">{{ errors[0] }}</small>
								</validation-provider>
							</b-form-group>
						</b-col>
						<b-col sm="4">
							<b-form-group
									:label="$i18n.t('application.legal-address')"
									label-for="legalAddress"
							>
								<validation-provider
										#default="{ errors }"
										name="legal address"
										rules="required"
								>
									<b-form-input
											v-model="data.legalAddress"
											:placeholder="$i18n.t('application.legal-address')"
											name="legalAddress"
									/>
									<small class="text-danger">{{ errors[0] }}</small>
								</validation-provider>
							</b-form-group>
						</b-col>
						<b-col sm="4">
							<b-form-group
									:label="$i18n.t('application.physical-address')"
									label-for="physicalAddress"
							>
								<validation-provider
										#default="{ errors }"
										name="physical address"
										rules="required"
								>
									<b-form-input
											v-model="data.physicalAddress"
											:placeholder="$i18n.t('application.physical-address')"
											name="physicalAddress"
									/>
									<small class="text-danger">{{ errors[0] }}</small>
								</validation-provider>
							</b-form-group>
						</b-col>
						<b-col v-if="userApplication.application.vendorType === 1" sm="4">
							<b-form-group
									:label="$i18n.t('application.director-fullName')"
									label-for="directorFullName"
							>
								<validation-provider
										#default="{ errors }"
										name="director full name"
										rules="required"
								>
									<b-form-input
											v-model="data.directorFullName"
											:placeholder="$i18n.t('application.director-fullName')"
											name="directorFullName"
									/>
									<small class="text-danger">{{ errors[0] }}</small>
								</validation-provider>
							</b-form-group>
						</b-col>
						<b-col v-if="userApplication.application.vendorType === 1" sm="4">
							<b-form-group
									:label="$i18n.t('application.director-phone')"
									label-for="directorMobile"
							>
								<validation-provider
										#default="{ errors }"
										name="director mobile number"
										rules="required"
								>
									<b-form-input
											v-model="data.directorMobile"
											:placeholder="$i18n.t('application.director-phone')"
											name="directorMobile"
									/>
									<small class="text-danger">{{ errors[0] }}</small>
								</validation-provider>
							</b-form-group>
						</b-col>
						<b-col v-if="userApplication.application.vendorType === 1" sm="4">
							<b-form-group
									:label="$i18n.t('application.accountant-fullName')"
									label-for="accountantFullName"
							>
								<b-form-input
										v-model="data.accountantFullName"
										:placeholder="$i18n.t('application.accountant-fullName')"
										name="accountantFullName"
								/>
							</b-form-group>
						</b-col>
						<b-col v-if="userApplication.application.vendorType === 1" sm="4">
							<b-form-group
									:label="$i18n.t('application.accountant-phone')"
									label-for="accountantMobile"
							>
								<b-form-input
										v-model="data.accountantMobile"
										:placeholder="$i18n.t('application.accountant-fullName')"
										name="accountantMobile"
								/>
							</b-form-group>
						</b-col>
						<b-col sm="4">
							<b-form-group
									:label="$i18n.t('application.iban')"
									label-for="iban"
							>
								<validation-provider
										#default="{ errors }"
										name="iBan"
										rules="required"
								>
									<b-form-input
											v-model="data.iban"
											:placeholder="$i18n.t('application.iban')"
											name="iban"
									/>
									<small class="text-danger">{{ errors[0] }}</small>
								</validation-provider>
							</b-form-group>
						</b-col>
						<b-col sm="4">
							<b-form-group
									:label="$i18n.t('application.other-contact-person-name')"
									label-for="otherContactPersonFullName"
							>
								<b-form-input
										v-model="data.otherContactPersonFullName"
										:placeholder="
										$i18n.t('application.other-contact-person-name')
									"
										name="otherContactPersonFullName"
								/>
							</b-form-group>
						</b-col>
						<b-col sm="4">
							<b-form-group
									:label="$i18n.t('application.other-contact-person-mobile')"
									label-for="otherContactPersonMobile"
							>
								<b-form-input
										v-model="data.otherContactPersonMobile"
										:placeholder="
										$i18n.t('application.other-contact-person-mobile')
									"
										name="otherContactPersonMobile"
								/>
							</b-form-group>
						</b-col>
						<b-col sm="4">
							<b-form-group
									:label="$i18n.t('application.warehouse-address')"
									label-for="warehouseAddress"
							>
								<b-form-input
										v-model="data.warehouseAddress"
										:placeholder="$i18n.t('application.warehouse-address')"
										name="warehouseAddress"
								/>
							</b-form-group>
						</b-col>
						<b-col sm="4">
							<b-form-group
									:label="$i18n.t('application.web-page')"
									label-for="webPage"
							>
								<b-form-input
										v-model="data.webPage"
										:placeholder="$i18n.t('application.web-page')"
										name="webPage"
								/>
							</b-form-group>
						</b-col>
						<b-col sm="4">
							<b-form-group
									:label="$i18n.t('application.facebook')"
									label-for="facebookLink"
							>
								<b-form-input
										v-model="data.facebookLink"
										:placeholder="$i18n.t('application.facebook')"
										name="facebookLink"
								/>
							</b-form-group>
						</b-col>
						<b-col sm="4">
							<b-form-group
									:label="$i18n.t('application.instagram')"
									label-for="instagramLink"
							>
								<b-form-input
										v-model="data.instagramLink"
										:placeholder="$i18n.t('application.instagram')"
										name="instagramLink"
								/>
							</b-form-group>
						</b-col>
						<b-col sm="4" class="d-flex align-items-center">
							<b-form-group
									:label="$i18n.t('application.logo')"
									label-for="logo"
									class="w-100"
							>
								<b-form-file
										v-model="data.logo"
										:placeholder="$i18n.t('application.logo')"
										drop-placeholder="Drop file here..."
										accept="image/*"
										no-drop
								/>
							</b-form-group>
							<div v-if="logoUrl" class="w-25 ml-1">
								<a :href="logoUrl" target="_blank">
									<img
											id="app-logo"
											:src="logoUrl"
											label="logo"
											class="rounded"
									/>
								</a>
							</div>
						</b-col>
						<b-col sm="4">
							<b-form-group
									:label="$i18n.t('application.branches')"
									label-for="branches"
							>
								<div class="d-flex flex-row">
									<b-form-input
											v-model="tempBranchGeo"
											:placeholder="$i18n.t('application.branches-geo')"
									/>
									<b-form-input
											v-model="tempBranchEng"
											:placeholder="$i18n.t('application.branches-eng')"
											class="left-margin"
									/>
									<b-button
											variant="primary"
											size="sm"
											class="left-margin"
											@click="addBranchToBranches"
									>
										<feather-icon size="16" icon="PlusIcon"/>
									</b-button>
								</div>
							</b-form-group>
						</b-col>
						<b-col sm="4">
							<label for="">Selected Branches</label>
							<div class="branches">
								<b-button
										v-for="branch in data.branches"
										:key="branch.nameGeo + Math.random()"
										variant="primary"
										class="left-margin bottom-margin branch-pill"
										size="sm"
										pill
										@click="deleteBranch(branch)"
								>
									{{ branch.nameGeo }} / {{ branch.nameEng }}
								</b-button>
							</div>
						</b-col>
						<!-- {{ selectedSegment }} -->
						<b-col sm="6">
							<label for="cat-multiselect">{{
									$i18n.t('application.segments')
								}}</label>
							<multiselect
									id="cat-multiselect"
									v-model="selectedCategory"
									:options="productCategories"
									:multiple="true"
									group-values="children"
									group-label="label"
									:group-select="true"
									:placeholder="$i18n.t('application.type-to-search')"
									label="label"
									track-by="label"
							>
								<span slot="noResult">
									Oops! No elements found. Consider changing the search query.
								</span>
							</multiselect>
						</b-col>
						</b-row>
					<b-row class="mt-2">
						<b-col md="6">
							<b-form-group
									:label="$i18n.t('application.description-geo')"
									label-for="logo"
							>
								<b-form-textarea
										id="textarea-default"
										v-model="data.descriptionGeo"
										:placeholder="$i18n.t('application.description-geo')"
										cols="3"
								/>
							</b-form-group>
						</b-col>
						<b-col md="6">
							<b-form-group
									:label="$i18n.t('application.description-eng')"
									label-for="logo"
							>
								<b-form-textarea
										id="textarea-default"
										v-model="data.descriptionEng"
										:placeholder="$i18n.t('application.description-eng')"
										cols="3"
								/>
							</b-form-group>
						</b-col>
					</b-row>
					<b-col class="pl-0" cols="12">
						<b-button
								variant="primary"
								class="mt-2 mr-1"
								@click.prevent="validationForm"
						>
							{{
								status === 0
										? $i18n.t('registration.submit')
										: $i18n.t('application.update')
							}}
						</b-button>
					</b-col>
				</b-form>
			</validation-observer>
		</b-card>
	</b-overlay>
</template>

<script>
import {
	BButton,
	BForm,
	BFormGroup,
	BFormInput,
	BFormTextarea,
	BOverlay,
	BFormCheckbox,
	BFormFile,
	BRow,
	BCol,
	BCard,
} from 'bootstrap-vue';
import { ValidationProvider, ValidationObserver } from 'vee-validate';
// eslint-disable-next-line import/no-extraneous-dependencies
import { required, email } from '@validations';
import { mapActions, mapState } from 'vuex';
import '@riophae/vue-treeselect/dist/vue-treeselect.css';
import objectToFormData from '@/helpers/objectToFormData';
import getDifference from '@/helpers/getDifference';
import Multiselect from 'vue-multiselect';

export default {
	components: {
		BButton,
		BForm,
		BFormGroup,
		BFormFile,
		BFormCheckbox,
		BFormTextarea,
		ValidationProvider,
		ValidationObserver,
		BOverlay,
		BFormInput,
		BRow,
		BCol,
		BCard,
		Multiselect,
	},
	data() {
		return {
			status: null,
			logoUrl: null,
			selectedCategory: [],
			tempBranchGeo: '',
			tempBranchEng: '',
			required,
			email,
			data: {
				legalNameOrFullName: '',
				contactPerson: '',
				contactPersonPosition: '',
				contactPersonMobile: '',
				brandName: '',
				legalAddress: '',
				physicalAddress: '',
				descriptionGeo: '',
				descriptionEng: '',
				directorFullName: '',
				directorMobile: '',
				accountantFullName: '',
				accountantMobile: '',
				iban: '',
				otherContactPersonFullName: '',
				otherContactPersonMobile: '',
				warehouseAddress: '',
				webPage: '',
				facebookLink: '',
				instagramLink: '',
				logo: null,
				isVatPayer: false,
				branches: [],
				newBranches: [],
				segments: [],
			},
		};
	},
	computed: {
		...mapState('accountSettings', [
			'userApplication',
			'userData',
			'showLoading',
		]),
		...mapState('vendorRegistration', ['productCategories']),
		computedApplicationStatus() {
			const applicationStatus = {
				0: this.$i18n.t('application.unsubmitted'),
				1: this.$i18n.t('application.pending'),
				2: this.$i18n.t('application.rejected'),
				3: this.$i18n.t('application.approved'),
			};
			// eslint-disable-next-line vue/no-side-effects-in-computed-properties
			this.status = this.userApplication?.application.applicationStatus;
			return applicationStatus[this.status];
		},
	},
	watch: {
		selectedCategory(newVal) {
			this.data.segments = newVal.map((e) => ({
				categoryId: e.categoryId,
				subCategoryId: e.subCategoryId,
			}));
			if (this.status !== 0) {
				this.data.segmentsUpdate.newVendorSegments = newVal.map((e) => ({
					categoryId: e.categoryId,
					subCategoryId: e.subCategoryId,
				}));
			}
		},
		// eslint-disable-next-line func-names
		'data.logo': function (newVal) {
			if (this.status !== 0) {
				this.data.logoUpdate = {
					isPreviousDeleted: true,
					newLogo: newVal,
				};
			}
		},
		userApplication(newVal) {
			if (this.status !== 0) {
				// eslint-disable-next-line no-console
				console.log('user application ', newVal);
				this.setApplicationForEdit(newVal);
			}
			this.data.vendorType = newVal.application.vendorType;
		},
	},
	created() {
		this.getProductCategories();
	},
	methods: {
		...mapActions('vendorRegistration', ['getProductCategories']),
		...mapActions('accountSettings', [
			'vendorApplication',
			'getUserApplication',
		]),
		setApplicationForEdit(application) {
			const app = application.application;

			this.data = {
				legalNameOrFullName: app.legalNameOrFullName,
				contactPerson: app.contactPerson,
				contactPersonPosition: app.contactPersonPosition,
				contactPersonMobile: app.contactPersonMobile,
				brandName: app.brandName,
				legalAddress: app.legalAddress,
				physicalAddress: app.physicalAddress,
				descriptionEng: app.descriptionEng,
				descriptionGeo: app.descriptionGeo,
				directorFullName: app.directorFullName,
				directorMobile: app.directorMobile,
				accountantFullName: app.accountantFullName,
				accountantMobile: app.accountantMobile,
				iban: app.iban,
				otherContactPersonFullName: app.otherContactPersonFullName,
				otherContactPersonMobile: app.otherContactPersonMobile,
				warehouseAddress: app.warehouseAddress,
				webPage: app.webPage,
				facebookLink: app.facebookLink,
				instagramLink: app.instagramLink,
				logo: null,
				isVatPayer: app.isVatPayer,
				branches: app.branches,
				branchUpdate: {
					deletedBranches: [],
					newBranches: [],
				},
				logoUpdate: {
					isPreviousDeleted: false,
					newLogo: null,
				},
				segmentsUpdate: {
					deletedVendorSegments: [],
					newVendorSegments: [],
				},
			};
			this.logoUrl = app.logoUrl;

			this.selectedCategory = app.vendorSegments.map((product) => ({
				categoryId: product.productCategoryId,
				subCategoryId: product.productSubCategoryId,
				label: `${product.subCategoryNameGeo} / ${product.subCategoryNameEng}`,
			}));
			this.existingBranhces = app.vendorSegments.map((product) => ({
				id: product.id,
				categoryId: product.productCategoryId,
				subCategoryId: product.productSubCategoryId,
				label: `${product.subCategoryNameGeo} / ${product.subCategoryNameEng}`,
			}));
		},

		addBranchToBranches() {
			if (this.tempBranchEng === '' || this.tempBranchGeo === '') return;
			const branch = {
				nameGeo: this.tempBranchGeo,
				nameEng: this.tempBranchEng,
			};

			this.data.branches.push(branch);

			// on edit push to branchupdate;
			if (this.status !== 0) {
				this.data.branchUpdate.newBranches.push(branch);
			}

			this.tempBranchGeo = '';
			this.tempBranchEng = '';
		},
		deleteBranch(branch) {
			const indexOfObject = this.data.branches.findIndex(
					(e) => e.nameGeo === branch.nameGeo && e.nameEng === branch.nameEng
			);
			this.data.branches.splice(indexOfObject, 1);

			// on edit push to branchupdate
			if (this.status !== 0 && branch.id) {
				this.data.branchUpdate.deletedBranches.push(branch.id);
			}
		},
		handleDeletedSegments() {
			const deletedBranches = getDifference(
					this.existingBranhces,
					this.selectedCategory
			);
			const addedBranches = getDifference(
					this.selectedCategory,
					this.existingBranhces
			);

			this.data.segmentsUpdate = {
				deletedVendorSegments: deletedBranches.length
						? deletedBranches.map((e) => e.id)
						: null,
				newVendorSegments: addedBranches.length
						? addedBranches.map((e) => ({
							categoryId: e.categoryId,
							subCategoryId: e.subCategoryId,
							// eslint-disable-next-line no-mixed-spaces-and-tabs
						}))
						: null,
			};
		},
		validationForm() {
			// handle deleted segments here`
			if (this.status !== 0) {
				this.handleDeletedSegments();
				this.data.branches = [];
				this.data.segments = [];
			}
			const formData = objectToFormData(this.data);

			this.$refs.userInfoValidation.validate()
					.then((success) => {
						if (success) {
							this.vendorApplication({
								formData,
								create:
								// eslint-disable-next-line no-unneeded-ternary
										this.status === 0,
							})
									.then(() => {
										this.getUserApplication();
									});
						}
					});
		},
	},
};
</script>
<style lang="scss" scoped>
.left-margin {
	margin-left: 0.5rem;
}

.bottom-margin {
	margin-bottom: 0.5rem;
}

.branch-pill:hover {
	color: rgb(155, 0, 0) !important;
	text-decoration: line-through;
}

#app-logo {
	width: 50px;
	height: auto;
}
</style>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
