<template>
	<b-card v-if="contractStatus !== 0" class="h-100">
		<b-col class="mt-1 mb-1 content-wrapper">
			<div class="w-100">
				<label>{{ $i18n.t('settings.download-and-sign-contract') }}</label>
			</div>
			<b-button
				variant="outline-primary"
				size="sm"
				class="mt-2"
				@click="downloadFile"
			>
				<div class="d-flex flex-col">
					<feather-icon icon="FileTextIcon" class="mr-50" />
					<span class="align-middle">
						{{ $i18n.t('settings.download-contract-pdf') }}
					</span>
				</div>
			</b-button>
			<div v-if="contractData" class="upload-wrapper">
				<label>{{ $i18n.t('settings.last-uploaded-in') }}- </label>
				<label
					v-if="contractData.adminLastUploadedAt"
					class="font-weight-bold text-primary"
				>
					{{
						contractData.adminLastUploadedAt.split(' ')[0].replaceAll('-', '/')
					}}
				</label>
			</div>
		</b-col>
		<b-col>
			<b-form-group
				:label="$i18n.t('settings.upload-your-contract')"
				label-for="attachement"
			>
				<b-form-file
					v-model="contractFile"
					size="sm"
					placeholder="Choose a file or drop it here..."
					drop-placeholder="Drop file here..."
					accept="application/pdf"
					no-drop
				/>
				<div v-if="contractData" class="upload-wrapper">
					<label>{{ $i18n.t('settings.last-uploaded-in') }} - </label>
					<label class="font-weight-bold text-primary">{{
						contractData.vendorLastUploadedAt.split(' ')[0].replaceAll('-', '/')
					}}</label>
				</div>
			</b-form-group>
		</b-col>
		<b-col class="d-flex flex-row align-items-center mt-2 mr-1">
			<b-button variant="primary" size="sm" @click="uploadFile">
				{{ $i18n.t('settings.save-changes') }}
			</b-button>
		</b-col>
	</b-card>
	<b-card v-else>
		<h3>
			{{ $i18n.t('settings.not-uploaded') }}
		</h3>
	</b-card>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import { BCol, BCard, BFormFile, BFormGroup, BButton } from 'bootstrap-vue';
import { mapState, mapActions } from 'vuex';
import Swal from 'sweetalert2';

export default {
	components: {
		BCol,
		BCard,
		BFormFile,
		BFormGroup,
		BButton,
	},

	props: {
		generalData: {
			type: Object,

			default: () => {},
		},
	},

	data() {
		return {
			contractFile: null,
			received: true,
		};
	},
	computed: {
		...mapState('accountSettings', ['contractStatus', 'contractData']),
	},
	methods: {
		...mapActions('accountSettings', ['userData', 'uploadContract']),
		downloadFile() {
			Swal.fire({
				title: 'Success!',
				text: 'Your contract is downloaded!',
				icon: 'success',
				confirmButtonText: 'Okay',
			});
			window.open(this.contractData.url);
		},
		uploadFile() {
			if (this.contractFile === null) {
				Swal.fire({
					title: 'Error!',
					text: 'Upload contract first!',
					icon: 'error',
					confirmButtonText: 'Okay',
				});
			} else {
				const formData = new FormData();
				formData.append('contractAttachment', this.contractFile);
				this.uploadContract(formData);
			}
		},
	},
};
</script>
