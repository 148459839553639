<template>
	<b-card>
		<validation-observer ref="userInfoValidation">
			<b-form v-if="optionsLocal" class="mt-2 d-flex">
				<div class="d-flex align-items-center">
					<div class="mr-2" style="padding-top: 5px">
						<b-dropdown
							v-if="optionsLocal"
							:text="$i18n.t('settings.roles')"
							size="small"
						>
							<b-dropdown-item
								v-for="(item, index) in optionsLocal.roles"
								:key="index"
							>
								{{ item }}
							</b-dropdown-item>
						</b-dropdown>
					</div>
					<b-form-group
						:label="$i18n.t('settings.e-mail')"
						label-for="account-e-mail"
						class="mr-2"
					>
						<b-form-input
							v-model="optionsLocal.email"
							name="email"
							disabled
							placeholder="Enter email here ..."
						/>
					</b-form-group>

					<b-form-group
						:label="$i18n.t('settings.mobile')"
						label-for="account-mobile"
						class="mr-2"
					>
						<validation-provider
							#default="{ errors }"
							name="Phone Number"
							rules="required"
						>
							<b-form-input
								v-model="optionsLocal.mobile"
								name="mobile"
								disabled
								placeholder="Enter mobile here ..."
							/>
							<small class="text-danger">{{ errors[0] }}</small>
						</validation-provider>
					</b-form-group>

					<div style="padding-top: 5px">
						<b-button
							v-ripple.400="'rgba(255, 255, 255, 0.15)'"
							variant="primary"
							class="mr-1"
							@click.prevent="validationForm"
						>
							{{ $i18n.t('settings.save-changes') }}
						</b-button>
					</div>
				</div>
			</b-form>
		</validation-observer>
	</b-card>
</template>

<script>
import {
	BButton,
	BForm,
	BFormGroup,
	BFormInput,
	BDropdown,
	BDropdownItem,
	BCard,
} from 'bootstrap-vue';
import Ripple from 'vue-ripple-directive';
import { ValidationProvider, ValidationObserver } from 'vee-validate';
import { required, email } from '@validations';
import { mapActions } from 'vuex';

export default {
	components: {
		BButton,
		BForm,
		BFormGroup,
		// BFormFile,
		BDropdown,
		BDropdownItem,
		ValidationProvider,
		ValidationObserver,
		BFormInput,
		BCard,
	},
	directives: {
		Ripple,
	},
	props: {
		generalData: {
			type: Object,
			default: () => {},
		},
	},
	data() {
		return {
			optionsLocal: this.generalData.data.info,
			required,
			email,
		};
	},
	methods: {
		...mapActions('accountSettings', ['updateUserInfo']),
		validationForm() {
			this.$refs.userInfoValidation.validate().then((success) => {
				if (success) {
					this.updateUserInfo({
						userName: this.optionsLocal.userName,
						email: this.optionsLocal.email,
						mobile: this.optionsLocal.mobile,
					});
				}
			});
		},
	},
};
</script>
