<template>
	<b-card class="h-100">
		<validation-observer ref="userPasswordValidation">
			<b-form>
				<!-- old password -->
				<b-form-group
					:label="$i18n.t('settings.old-password')"
					label-for="account-old-password"
					class="w-75"
				>
					<validation-provider
						#default="{ errors }"
						name="old password"
						rules="required"
					>
						<b-input-group class="input-group-merge">
							<b-form-input
								id="account-old-password"
								v-model="passwordValueOld"
								name="old-password"
								:type="passwordFieldTypeOld"
								placeholder="Old Password"
							/>
							<b-input-group-append is-text>
								<feather-icon
									:icon="passwordToggleIconOld"
									class="cursor-pointer"
									@click="togglePasswordVisibilityOld"
								/>
							</b-input-group-append>
						</b-input-group>
						<small class="text-danger">{{ errors[0] }}</small>
					</validation-provider>
				</b-form-group>
				<b-form-group
					label-for="account-new-password"
					:label="$i18n.t('settings.new-password')"
					class="w-75"
				>
					<validation-provider
						#default="{ errors }"
						name="old password"
						rules="required"
					>
						<b-input-group class="input-group-merge">
							<b-form-input
								id="account-new-password"
								v-model="newPasswordValue"
								:type="passwordFieldTypeNew"
								name="new-password"
								placeholder="New Password"
							/>
							<b-input-group-append is-text>
								<feather-icon
									:icon="passwordToggleIconNew"
									class="cursor-pointer"
									@click="togglePasswordVisibilityNew"
								/>
							</b-input-group-append>
						</b-input-group>
						<small class="text-danger">{{ errors[0] }}</small>
					</validation-provider>
				</b-form-group>
				<b-form-group
					label-for="account-retype-new-password"
					:label="$i18n.t('settings.retype-new-password')"
					class="w-75"
				>
					<validation-provider
						#default="{ errors }"
						name="old password"
						rules="required"
					>
						<b-input-group class="input-group-merge">
							<b-form-input
								id="account-retype-new-password"
								v-model="retypePassword"
								:type="passwordFieldTypeRepeat"
								name="retype-password"
								placeholder="New Password" />
							<b-input-group-append is-text>
								<feather-icon
									:icon="passwordToggleIconRetype"
									class="cursor-pointer"
									@click="togglePasswordVisibilityRepeat"
								/> </b-input-group-append
						></b-input-group>
						<small class="text-danger">{{ errors[0] }}</small>
					</validation-provider></b-form-group
				>
				<b-button
					v-ripple.400="'rgba(255, 255, 255, 0.15)'"
					variant="primary"
					class="mt-1 mr-1"
					size="sm"
					@click.prevent="validationForm"
				>
					{{ $i18n.t('settings.save-changes') }}
				</b-button>
				<b-button
					v-ripple.400="'rgba(186, 191, 199, 0.15)'"
					type="reset"
					size="sm"
					variant="outline-secondary"
					class="mt-1"
				>
					{{ $i18n.t('settings.reset') }}
				</b-button>
			</b-form>
		</validation-observer></b-card
	>
</template>

<script>
import {
	BButton,
	BForm,
	BFormGroup,
	BFormInput,
	BCard,
	BInputGroup,
	BInputGroupAppend,
} from 'bootstrap-vue';
import Ripple from 'vue-ripple-directive';
import { onUnmounted } from '@vue/composition-api';
import store from '@/store/index';
import authModule from '@/store/auth';
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';
import { ValidationProvider, ValidationObserver } from 'vee-validate';

export default {
	components: {
		BButton,
		BForm,
		ValidationProvider,
		ValidationObserver,
		BFormGroup,
		BFormInput,
		BCard,
		BInputGroup,
		BInputGroupAppend,
	},
	directives: {
		Ripple,
	},
	setup() {
		const AUTH_MODULE_NAME = 'auth';

		// Register module
		if (!store.hasModule(AUTH_MODULE_NAME)) {
			store.registerModule(AUTH_MODULE_NAME, authModule);
		}

		// UnRegister on leave
		onUnmounted(() => {
			if (store.hasModule(AUTH_MODULE_NAME)) {
				store.unregisterModule(AUTH_MODULE_NAME);
			}
		});
	},
	data() {
		return {
			passwordValueOld: '',
			newPasswordValue: '',
			retypePassword: '',
			passwordFieldTypeOld: 'password',
			passwordFieldTypeNew: 'password',
			passwordFieldTypeRepeat: 'password',
		};
	},
	computed: {
		passwordToggleIconOld() {
			return this.passwordFieldTypeOld === 'password'
				? 'EyeIcon'
				: 'EyeOffIcon';
		},
		passwordToggleIconNew() {
			return this.passwordFieldTypeNew === 'password'
				? 'EyeIcon'
				: 'EyeOffIcon';
		},
		passwordToggleIconRetype() {
			return this.passwordFieldTypeRepeat === 'password'
				? 'EyeIcon'
				: 'EyeOffIcon';
		},
	},
	methods: {
		togglePasswordVisibilityOld() {
			// eslint-disable-next-line operator-linebreak
			this.passwordFieldTypeOld =
				this.passwordFieldTypeOld === 'password' ? 'text' : 'password';
		},
		togglePasswordVisibilityNew() {
			// eslint-disable-next-line operator-linebreak
			this.passwordFieldTypeNew =
				this.passwordFieldTypeNew === 'password' ? 'text' : 'password';
		},
		togglePasswordVisibilityRepeat() {
			// eslint-disable-next-line operator-linebreak
			this.passwordFieldTypeRepeat =
				this.passwordFieldTypeRepeat === 'password' ? 'text' : 'password';
		},
		validationForm() {
			this.$refs.userPasswordValidation.validate().then((success) => {
				if (success) {
					this.$store
						.dispatch('auth/changePassword', {
							currentPassword: this.passwordValueOld,
							password: this.newPasswordValue,
							repeteadPassword: this.retypePassword,
							// eslint-disable-next-line no-unused-vars
						})
						.then(() => {});
					this.$toast({
						component: ToastificationContent,
						props: {
							title: 'Your password is successfully changed!',
							icon: 'EditIcon',
							variant: 'success',
						},
					});
					this.passwordValueOld = '';
					this.newPasswordValue = '';
					this.retypePassword = '';
				}
			});
		},
	},
};
</script>
